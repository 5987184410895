import React, { useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';

const DiffusionImage = () => {
  const [userInput, setUserInput] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const handleChange = (e) => {
    setUserInput(e.target.value);
  };

  
const getDiffusionImage = async (input) => {
    try {
      const response = await axios.post('/api/stabilityImage', {
        prompt: input,
      });
      //console.info('------',response.data);
      return response.data.imageData[0].base64;
    } catch (error) {
      console.error('Error fetching diffusion image:', error);
      return '';
    }
  };

  const mutation = useMutation(getDiffusionImage, {
    onSuccess: (data) => {
      setImageUrl(data);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(userInput);
  };

  return (
    <div className="diffusion-image-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter your prompt"
          value={userInput}
          onChange={handleChange}
        />
        <button type="submit" disabled={mutation.isLoading}>
          {mutation.isLoading ? 'Loading...' : 'Generate Image'}
        </button>
      </form>
      {imageUrl && <img src={`data:image/jpeg;base64,${imageUrl}`} alt="Generated" />}
    </div>
  );
};

export default DiffusionImage;
