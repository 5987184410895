import logo from './frugai_logo.jpeg';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import DiffusionImage from './components/DiffusionImage';

const queryClient = new QueryClient();
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        A next-gen AI-driven ad creation tool for crafting cost-effective, engaging promotions.
        </p>
        <a
          className="App-link"
          href="https://lablab.ai/event/stable-diffusion-ai-hackathon/frugai"
          target="_blank"
          rel="noopener noreferrer"
        >
          more info
        </a>
      </header>
      <QueryClientProvider client={queryClient}>
        <header className="App-header">
          <h3>test prompt</h3>
          <DiffusionImage />
        </header>
    </QueryClientProvider>
    </div>
  );
}

export default App;
